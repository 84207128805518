import * as actiontypes from '../actiontypes/ActionTypes';

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_USER":
      return { status: action.responseObject.status };
    case actiontypes.GET_USER_BY_ID:
      return action.response;
    default:
      return state;
  }
};

export default userReducer;