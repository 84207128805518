import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { setAuthorization } from "../../actions/UserActions";
import { clearAuthenticationData } from "../../config/utility";
import { useDispatch } from "react-redux";
import { getUserPreferences } from "../../utility";

const Sidebar = (props) => {

  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [menuState, setMenuState] = useState({
    managestudentsMenuOpen: false,
    managefeesMenuOpen: false,
    transportMenuOpen: false,
    attendanceMenuOpen: false,
    chartsMenuOpen: false,
    userPagesMenuOpen: false,
    errorPagesMenuOpen: false,
    manageUserPageMenuOpen: false,
  });

  const userPref = getUserPreferences();
  const renderUserName = () => {
    return userPref.firstName !== null ? userPref.firstName : "" + " " + userPref.lastName !== null ? userPref.lastName : "";
  }

  const toggleMenuState = (newMenuState) => {
    if (menuState[newMenuState]) {
      setMenuState({ [newMenuState]: false });
    } else if (Object.keys(newMenuState).length === 0) {
      setMenuState({ [newMenuState]: true });
    } else {
      Object.keys(menuState).forEach(i => {
        setMenuState({ [i]: false });
      });
      setMenuState({ [newMenuState]: true });
    }
  }

  useEffect(() => {
    if (location) {
      onRouteChanged();
    }
  }, [location]);

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(menuState).forEach(i => {
      setMenuState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/students", state: "managestudentsMenuOpen" },
      { path: "/admission", state: "managestudentsMenuOpen" },
      { path: "/feecollection", state: "managestudentsMenuOpen" },
      { path: "/managefees", state: "managefeesMenuOpen" },
      { path: "/fee-structures", state: "managefeesMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/transports", state: "transportMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/teacher-attendances", state: "attendanceMenuOpen" },
      { path: "/student-attendances", state: "attendanceMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
      { path: "/users/add-user", state: "manageUserPageMenuOpen" },
      { path: "/users/user-list", state: "manageUserPageMenuOpen" }
    ];

    dropdownPaths.forEach(obj => {
      if (isPathActive(obj.path)) {
        setMenuState({ ...menuState, [obj.state]: true });
      }
    });
  }

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  }

  useEffect(() => {
    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach(el => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
          // if (el.querySelector('.ReactCollapse--collapse')) {
          //   el.querySelector('.ReactCollapse--collapse').setAttribute('style', 'height: auto; overflow: initial;');
          // }
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
          // if (el.querySelector('.ReactCollapse--collapse')) {
          //   el.querySelector('.ReactCollapse--collapse').setAttribute('style', 'height: 0; overflow: hidden;');
          // }
        }
      });
    });
  }, []);



  return (
    <nav className="sidebar sidebar-offcanvas no-print" id="sidebar">
      <ul className="nav">
        <li className="nav-item navbar-brand-mini-wrapper">
          <Link
            className="nav-link navbar-brand brand-logo-mini"
            to="/dashboard"
          >
            <img
              height={"28px"}
              src={require("../../assets/images/theschoolworld-small.png")}
              alt="logo"
            />
          </Link>
        </li>
        <li className="nav-item nav-profile">
          <a
            href="!#"
            className="nav-link"
            onClick={evt => evt.preventDefault()}
          >
            <div className="profile-image">
              <img
                className="img-xs rounded-circle"
                src={require("../../assets/images/faces/face8.jpg")}
                alt="profile"
              />
              <div className="dot-indicator bg-success"></div>
            </div>
            <div className="text-wrapper">
              <p className="profile-name">{renderUserName()}</p>
              <p className="designation">
                Administrator
              </p>
            </div>
            <div className="icon-container">
              <i className="icon-bubbles"></i>
              <div className="dot-indicator bg-danger"></div>
            </div>
          </a>
        </li>
        <li className="nav-item nav-category">
          <span className="nav-link">
            Main Section
          </span>
        </li>
        <li
          className={
            isPathActive("/dashboard") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/dashboard">
            <span className="menu-title">
              Dashboard
            </span>
            <i className="icon-screen-desktop menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            (isPathActive("/admission") || isPathActive("/students") || isPathActive("/feecollection")) ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              menuState.managestudentsMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("managestudentsMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              Manage students
            </span>
            <i className="icon-layers menu-icon"></i>
          </div>
          <Collapse in={menuState.managestudentsMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/admission")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/admission"
                  >
                    Admission
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/students")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/students"
                  >
                    Search and Update
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/feecollection")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/feecollection?showcurrentdueonly=true"
                  >
                    Collect Fee
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            isPathActive("/managefees")
              ? "nav-item active"
              : "nav-item"
          }
        >
          <div
            className={
              menuState.managefeesMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("managefeesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              Manage fees
            </span>
            <i className="icon-book-open menu-icon"></i>
          </div>
          <Collapse in={menuState.managefeesMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/managefees")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/managefees"
                  >
                    Fee details
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/fee-structures")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/fee-structures"
                  >
                    Fee structures
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            isPathActive("/transports") ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              menuState.transportMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("transportMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              Manage transports
            </span>
            <i className="icon-grid menu-icon"></i>
          </div>
          <Collapse in={menuState.transportMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/transports/students")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/transports/students"
                  >
                    Assign student
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/transports/vendors")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/transports/vendors"
                  >
                    Manage vendors
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/transports/routes")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/transports/routes"
                  >
                    Manage routes
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/transports/locations")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/transports/locations"
                  >
                    Manage locations
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            (isPathActive("/student-attendances") || isPathActive("/teacher-attendances")) ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              menuState.attendanceMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("attendanceMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              Manage attendances
            </span>
            <i className="icon-globe menu-icon"></i>
          </div>
          <Collapse in={menuState.attendanceMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/student-attendances")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/student-attendances"
                  >
                    Student attendance
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/teacher-attendances")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/teacher-attendances"
                  >
                    Teacher attendance
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            isPathActive("/charts") ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              menuState.chartsMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("chartsMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              Manage results
            </span>
            <i className="icon-chart menu-icon"></i>
          </div>
          <Collapse in={menuState.chartsMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/charts/chart-js")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/chart-js"
                  >
                    Student results
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        {/* User Menu Starts*/}
        <li
          className={
            isPathActive("/users") ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              menuState.manageUserPageMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("manageUserPageMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              Manage users
            </span>
            <i className="icon-user menu-icon"></i>
          </div>
          <Collapse in={menuState.manageUserPageMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/users/add-user")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/users/add-user"
                  >
                    Create user
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/users/user-list")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/users/user-list"
                  >
                    User list
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        {/* User Menu Ends*/}
        <li className="nav-item nav-category">
          <span className="nav-link">
            Reports
          </span>
        </li>
        <li
          className={
            isPathActive("/user-pages") ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              menuState.userPagesMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("userPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              Fee reports
            </span>
            <i className="icon-disc menu-icon"></i>
          </div>
          <Collapse in={menuState.userPagesMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/user-pages/login-1")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user-pages/login-1"
                  >
                    Fee summary
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/user-pages/login-1")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user-pages/login-1"
                  >
                    Fee transaction
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            isPathActive("/error-pages") ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              menuState.errorPagesMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("errorPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              Attendance reports
            </span>
            <i className="icon-flag menu-icon"></i>
          </div>
          <Collapse in={menuState.errorPagesMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/error-pages/error-404")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/error-pages/error-404"
                  >
                    Student attendance
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/error-pages/error-500")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/error-pages/error-500"
                  >
                    Teacher attendance
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li className="small-screen-menu nav-item nav-category">
          <span className="nav-link">
            User Actions
          </span>
        </li>
        <li className="small-screen-menu nav-item">
          <a
            className="nav-link"
            onClick={() => navigate("/profile")}
            rel="noopener noreferrer"
          >
            <span className="menu-title">
              Edit Profile
            </span>
            <i className="icon-user menu-icon text-primary"></i>
          </a>
        </li>
        <li className="small-screen-menu nav-item">
          <a
            className="nav-link"
            href="#"
            onClick={() => {
              clearAuthenticationData();
              dispatch(setAuthorization("false")).then(() =>
                navigate("/login")
              );
            }}
            rel="noopener noreferrer"
          >
            <span className="menu-title">
              Sign Out
            </span>
            <i className="icon-power menu-icon text-primary"></i>
          </a>
        </li>
      </ul>
    </nav>)
}


export default Sidebar;
