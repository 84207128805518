import * as actionTypes from '../actiontypes/ActionTypes';

function createUser(url, user) {
  return {
    type: "CREATE_USER",
    meta: {
      api: {
        method: "post",
        url: url,
        data: user,
      },
    },
  };
}

function patchUpdateClientUser(url, user) {
  return {
    type: actionTypes.PATCH_UPDATE_CLIENT_USER,
    meta: {
      api: {
        method: "patch",
        url: url,
        data: user,
      },
    },
  };
}

function userPreferences(url) {
  return {
      type: "userPreferences",
      meta: {
        api: {
            method: "Get",
            url: url,
        },
      },
    };
}

function getUserById(url) {
  return {
      type: actionTypes.GET_USER_BY_ID,
      meta: {
        api: {
            method: "Get",
            url: url,
        },
      },
    };
}

function setAuthorization(isAuth) {
  return {
      type: "userAuth",
      isAuth: isAuth
    };
}


export {createUser , userPreferences , setAuthorization, patchUpdateClientUser, getUserById};
