export const GET_FEES = "GET_FEES";
export const GET_FEE_TYPES = "GET_FEE_TYPES";
export const GET_FEE_FREQUENCIES = "GET_FEE_FREQUENCIES";
export const CLEAR_FEES = "CLEAR_FEES";
export const UPDATE_FEE_STATUS = "UPDATE_FEE_STATUS";
export const UPDATE_FEE_SELECTION = "UPDATE_FEE_SELECTION";
export const ADJUST_FEE_PAID = "ADJUST_FEE_PAID";
export const SET_FEE_PRINT_VIEW = "SET_FEE_PRINT_VIEW";
export const STUDENT_REGISTRATION = "STUDENT_REGISTRATION";
export const SEARCH_STUDENTS = "SEARCH_STUDENTS";
export const CLEAR_SEARCH_STUDENTS = "CLEAR_SEARCH_STUDENTS";
export const CLEAR_REGISTRATION_RESPONSE = "CLEAR_REGISTRATION_RESPONSE";
export const ADD_TRANSPORT_ROUTE = "ADD_TRANSPORT_ROUTE";
export const GET_TRANSPORT_ROUTE = "GET_TRANSPORT_ROUTE";
export const CLEAR_TRANSPORT_ROUTE = "CLEAR_TRANSPORT_ROUTE";
export const ADD_TRANSPORT_LOCATION = "ADD_TRANSPORT_LOCATION";
export const GET_TRANSPORT_LOCATION = "GET_TRANSPORT_LOCATION";
export const CLEAR_TRANSPORT_LOCATION = "CLEAR_TRANSPORT_LOCATION";
export const UPDATE_TRANSPORT_LOCATION_SELECTION = "UPDATE_TRANSPORT_LOCATION_SELECTION"
export const ADD_TRANSPORT_VEDNOR = "ADD_TRANSPORT_VEDNOR";
export const GET_TRANSPORT_VEDNOR = "GET_TRANSPORT_VEDNOR";
export const ASSIGN_TRANSPORT_ROUTE_LOCATION = "ASSIGN_TRANSPORT_ROUTE_LOCATION";
export const GET_TRANSPORT_ROUTE_LOCATIONS = "GET_TRANSPORT_ROUTE_LOCATIONS";
export const REMOVE_ASSIGN_TRANSPORT_ROUTE_LOCATION = "REMOVE_ASSIGN_TRANSPORT_ROUTE_LOCATION";
export const REMOVE_TRANSPORT_ROUTE_LOCATION_ASSIGNMENT = "REMOVE_TRANSPORT_ROUTE_LOCATION_ASSIGNMENT";
export const GET_TRANSPORT_VEDNOR_LOCATION_FEES = "GET_TRANSPORT_VEDNOR_LOCATION_FEES";
export const ADD_TRANSPORT_VEDNOR_LOCATION_FEES = "ADD_TRANSPORT_VEDNOR_LOCATION_FEES";
export const REMOVE_TRANSPORT_VEDNOR_LOCATION_FEES = "REMOVE_TRANSPORT_VEDNOR_LOCATION_FEES";
export const ADD_STUDENT_TRANSPORT_TRANSACTION = "ADD_STUDENT_TRANSPORT_TRANSACTION";
export const GET_STUDENT_TRANSPORT_TRANSACTIONS = "GET_STUDENT_TRANSPORT_TRANSACTIONS";
export const PATCH_UPDATE_CLIENT_USER = "PATCH_UPDATE_CLIENT_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_FEE_STRUCTURES = "GET_FEE_STRUCTURES";
export const GET_FEE_STRUCTURE_BY_ID = "GET_FEE_STRUCTURE_BY_ID";
export const CREATE_FEE_STRUCTURE_BY_ID = "CREATE_FEE_STRUCTURE_BY_ID";