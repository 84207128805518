import { useEffect, useState } from "react";
import "./Toast.css";

const Toast = ({ visible, onCloseClick, message, type }) => {
  const [hidden, setHiddine] = useState(false);

  useEffect(() => {
    if (type != "error") {
      // setTimeout(() => { setHiddine(true) }, 2000);
    }

  }, [])
  return (
    visible === true && (
      <section className={type === "error" ? "toast-main" : "col-lg-12"} >
        <section className="row">
          <section className={type === "error" ? "errorContainer" : "col-lg-12 success-container"}>
            <section> {message}
              <span
                className="errorCross"
                onClick={() => {
                  onCloseClick(false);
                }}
              >
                X
              </span>
            </section>

          </section>
        </section>
      </section >
    )
  );
};

export default Toast;
