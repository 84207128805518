function getBaseUrl() {
    // return "https://etosms.azurewebsites.net/";
    return "https://etosmsdev1.azurewebsites.net/";
    //return "https://localhost:7289/";
}

function clearAuthenticationData() {
    sessionStorage.removeItem("token");
    localStorage.removeItem("userPreferences");
}

export { getBaseUrl, clearAuthenticationData };