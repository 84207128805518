import * as actiontypes from '../actiontypes/ActionTypes';

const feeStructureReducer = (state = [], action) => {
    switch (action.type) {
        case actiontypes.GET_FEE_STRUCTURES: {
            return action.response;
        }
        default:
            return state;
    }
};

const singleFeeStructureReducer = (state = [], action) => {
    switch (action.type) {
        case actiontypes.GET_FEE_STRUCTURE_BY_ID: {
            return action.response;
        }
        default:
            return state;
    }
};

export { feeStructureReducer, singleFeeStructureReducer };